import React, { Component } from 'react';
import {share, FACEBOOK, TWITTER, MAIL, WHATSAPP} from '../globals/share.js';
import facebook from '../../assets/images/facebook-x2.png';
import message from '../../assets/images/messages-x2.png';
import whatsapp from '../../assets/images/whatsapp-x2.png';
import twitter from '../../assets/images/twitter-x2.png';

class SocialNetworks extends Component {

  sharePage = (socialMedia) => {
    var win;
    var slug = this.props.podcast ? this.props.podcast.slug : null;
    var title = this.props.podcast ? this.props.podcast.title.rendered : "";
    if(slug){
      win = window.open(share(socialMedia, slug, title), '_blank');
    }else{
      win = window.open(share(socialMedia, null, title), '_blank');
    }
    
    win.focus();
  }

  render() {
    return (
      <ul className="p-0 d-flex justify-content-center mb-0 list-style-none">
        <li onClick={() => {this.sharePage(FACEBOOK)} }><img src={facebook} className="social-network mx-2 cursor" alt="logo" /></li>
        <li onClick={() => {this.sharePage(TWITTER)} }><img src={twitter} className="social-network mx-2 cursor" alt="logo" /></li>
        <li onClick={() => {this.sharePage(WHATSAPP)} }><img src={whatsapp} className="social-network mx-2 cursor" alt="logo" /></li>
        <li onClick={() => {this.sharePage(MAIL)} }><img src={message} className="social-network mx-2 cursor" alt="logo" /></li>
      </ul>
    );
  }
}

export default SocialNetworks;
