import React, { Component } from 'react';
import './RelatedPodcast.css';
import sound from '../../assets/images/sound-related-podcast.png';
import gallery from '../../assets/images/gallery.png';
import link from '../../assets/images/link.png';
import camera from '../../assets/images/camera.png';
import libro from '../../assets/images/icono-libro.png';
import Carousel from '../carousel/Carousel';


class RelatedPodcast extends Component {
  get_image_type(podcast_type){
    if(podcast_type.toUpperCase() === "VIDEO"){
        return camera;
    }else if(podcast_type.toUpperCase() === "GALERÍA"){
        return gallery;
    }else{
      return link;
    }
  };
  render(){
    return(
      <div>
        <div className="container related-content">
          <div className="row">
            <div className="col col-sm-10 offset-sm-1">
              {this.props.podcastAcf && this.props.relatedpodcast?
              (
                <div>
                  <div className="row mb-4 align-items-center">
                    <div className="col col-md-6">
                      <h2 className="related-title h1">Contenidos relacionados</h2>
                    </div>
                    <div className="col d-none d-md-block col-md-6">
                      <img src={sound} alt="sound" className="img-fluid"/>
                    </div>
                  </div>
                  {this.props.relatedpodcast.map((podcast,i) => 
                    <div key={i}>
                      {podcast.tipo === 'Galería' && podcast.imagenes ? (
                        <div className="row mb-5">
                          <div className="col-md-4">
                            <button className="btn btn-link w-100" data-toggle="modal" data-target={`#gallerymodal-${i}`}>
                              <div className="position-relative cursor">
                                <img src={podcast.thumbnail} className="blog-image img-fluid w-100" alt="featured blog" />
                                <div className="position-absolute content-type">
                                  <img src={this.get_image_type(podcast.tipo)} className="blog-image img-fluid" alt="featured blog" />
                                </div>
                              </div>
                            </button>
                          </div>
                          <div className="col-md-8">
                            <p className="mb-2 link-color text-uppercase font-700 letter-2 mt-3">{podcast.tipo}</p>
                            <h4 className="mb-2">
                              <button className="color-base btn btn-link" data-toggle="modal" data-target={`#gallerymodal-${i}`}>
                                {podcast.titulo}
                              </button>
                            </h4>
                            <p className="mb-0 fs-18">{podcast.descripcion}</p>
                          </div>
                          <div className="modal fade gallery-modal" id={`gallerymodal-${i}`} tabIndex="-1" role="dialog" aria-labelledby={`gallerymodal-${i}`} aria-hidden="true">
                            <div className="modal-dialog" role="document">
                              <div className="modal-content bg-transparent border-0">
                                <div className="modal-body">
                                  <p className="font-700 text-center text-white">Galería</p>
                                  <div className="images-div bg-white p-3">
                                    <Carousel images={podcast.imagenes}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ):(
                        <div className="row mb-5">
                          <div className="col-md-4">
                            <a href={podcast.enlace_externo} target="_blank" rel="noopener noreferrer">
                              <div className="position-relative">
                                <img src={podcast.thumbnail} className="blog-image img-fluid w-100" alt="featured blog" />
                                <div className="position-absolute content-type">
                                  <img src={this.get_image_type(podcast.tipo)} className="blog-image img-fluid" alt="featured blog" />
                                </div>
                                {podcast.tipo.toUpperCase() === "LIBRO" &&
                                  <div className="position-absolute book-type">
                                    <img src={libro} className="blog-image img-fluid" alt="featured blog" />
                                  </div>
                                }
                              </div>
                            </a>
                          </div>
                          <div className="col-md-8">
                            <p className="mb-2 link-color text-uppercase font-700 letter-2 mt-3">{podcast.tipo}</p>
                            <h4 className="mb-2">
                              <a href={podcast.enlace_externo} className="color-base" target="_blank" rel="noopener noreferrer">
                                {podcast.titulo}
                              </a>
                            </h4>
                            <p className="mb-0 fs-18">{podcast.descripcion}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ):(
                <div>
                {/*  <p>No hay contenido relacionado</p> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.podcastAcf && this.props.relatedpodcast &&
          <hr className="border-2 border-green-100 mb-5"/>
        }
      </div>
    );
  }
}

export default RelatedPodcast;

