import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import './index.css';
import store from "./store/store";
import App from './App';
import axios from "axios";
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/umd/popper'
import 'bootstrap/dist/js/bootstrap.min.js';
import Moment from 'react-moment';
import 'moment/locale/es';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
Moment.globalLocale = 'es';
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'));

registerServiceWorker();
