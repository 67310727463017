import React, { Component } from 'react';
import '../App.css';
import Home from '../pages/Home'

class Default extends Component {
  render() {
    return (
      <div>
        <Home onPlayerClick={this.props.onPlayerClick}/>
      </div>
    );
  }
}

export default Default;
