import axios from 'axios';
import TagManager from 'react-gtm-module'

export const UPDATE_PODCASTS = 'UPDATE_PODCASTS'
export const UPDATE_PODCAST = 'UPDATE_PODCAST'
export const UPDATE_PODCAST_RECOMMENDED = 'UPDATE_PODCAST_RECOMMENDED'
export const NO_PODCAST = 'NO_PODCAST'
export const UPDATE_PLAYING_PODCAST = 'UPDATE_PLAYING_PODCAST'
export const CLEAR_PLAYING_PODCAST = "CLEAR_PLAYING_PODCAST"
export const UPDATE_CURRENTLY_PLAYING = 'UPDATE_CURRENTLY_PLAYING'


const updateCdnUrl = (json) => {
  if (process.env.REACT_APP_CDN_URL) {
    var jsonString = JSON.stringify(json);
    var wpBaseUrl = process.env.REACT_APP_API_URL.replace("wp-json/wp/v2/", "wp-content/uploads/");
    jsonString = jsonString.split(wpBaseUrl).join(`${process.env.REACT_APP_CDN_URL}wp-content/uploads/`);
    json = JSON.parse(jsonString);
  }
  return json;
}

export const updatePodcasts = (data) => {
  return {
    type: UPDATE_PODCASTS,
    payload: data
  }
}

export const updatePodcast = (data) => {
  return {
    type: UPDATE_PODCAST,
    payload: data
  }
}
export const updatePodcastRecommended = (data) => {
  return {
    type: UPDATE_PODCAST_RECOMMENDED,
    payload: data
  }
}

export const playingPodcast = (data) => {
  if (process.env.REACT_APP_GTM) {
    TagManager.dataLayer({
      dataLayer: {
        title: data.playingPodcast.title.rendered,
        isPlaying: true,
        event: "podcastPlay"
      },
      dataLayerName: 'Podcast',
    })
  }
  return {
    type: UPDATE_PLAYING_PODCAST,
    payload: data
  }
}

export const clearPlayingPodcast = (data) => {
  return {
    type: CLEAR_PLAYING_PODCAST,
    payload: null
  }
}

export const updateCurrentlyPlaying = (data) => {
  return {
    type: UPDATE_CURRENTLY_PLAYING,
    payload: data
  }
}

export const getPodcasts = (page) => {
  return dispatch => {
    return axios.get(`podcast/?_embed&per_page=3&page=${page}`)
    .then(response => {
    	dispatch(updatePodcasts({
    		podcasts: updateCdnUrl(response.data),
        page,
        totalPages: response.headers['x-wp-totalpages']
    	}))
    }).catch(error => {
    	console.log(error)
    })
  }
}

export const updatePlayingPodcast = (podcast) => {
  var next = podcast.next;
  var prev = podcast.prev;
  return dispatch => {
    return axios.get(`podcast?_embed&per_page=2&include=${next},${prev}`)
    .then(response => {
      dispatch(playingPodcast({
        playingPodcast: podcast,
        nextPodcast: updateCdnUrl(response.data).find(podcast => podcast.id === next),
        prevPodcast: updateCdnUrl(response.data).find(podcast => podcast.id === prev),
      }))
    }).catch(error => {
      console.log(error)
    })
  }
}

export const getPodcast = (slug) => {

  return dispatch => {
    return axios.get(`podcast/?_embed&slug=${slug}`)
    .then(response => {
      return response;
    }).then((response_podcast) => {
      var podcast = updateCdnUrl(response_podcast.data)[0];
      if(response_podcast.data.length === 0){

        dispatch(updatePodcastRecommended({
            currentPodcast: NO_PODCAST
          }))
        return;
      }
      return axios.get(`podcast/?_embed&per_page=2&exclude=${podcast.id}`)
      .then(response => {

        dispatch(updatePodcastRecommended({
          podcasts: updateCdnUrl(response.data),
          currentPodcast: podcast
        }))
      }).catch(error => {
        console.log(error)
      })
      // do something with Apple res
    }).catch(error => {
    	if (error.response && error.response.status===404){
        dispatch(updatePodcast({
          currentPodcast: NO_PODCAST
        }))
      }
    })
  }
}
