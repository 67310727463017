import React, { Component } from 'react';
import { connect } from "react-redux";
import renderHTML from 'react-render-html';
import './PodcastDetail.css';
import Moment from 'react-moment';
import calendar from '../../assets/images/calendar.png';
import play from '../../assets/images/btn-play.png';
import playingGif from '../../assets/images/playing.gif';
import arrow from '../../assets/images/up-arrow.png';
import podcastLoading from '../../assets/images/podcast-loading.gif';
import ScrollIntoView from 'react-scroll-into-view';
import SocialNetworks from '../socialNetworks/SocialNetworks';

class PodcastDetail extends Component {
    constructor(props){
      super(props);
      this.state = {show_transcription: false};
      this.change_status_transcription = this.change_status_transcription.bind(this);
      this.close_transcription = this.close_transcription.bind(this);
      this.btnTranscription = React.createRef();
    }
    change_status_transcription(){
      this.setState({show_transcription:!this.state.show_transcription})
    }
    close_transcription(){
      this.setState({show_transcription:false})
    }
    render(){
      var onClickMethod = () => this.props.onPlay(this.props.podcast);
      var playerImage = play;
      var podcast = this.props.podcast;
      if (podcast && this.props.playingPodcast) {
        if (podcast.slug === this.props.playingPodcast.slug) {
          if (this.props.currentlyPlaying === 'playing') {
            playerImage = playingGif;
          }else if (this.props.currentlyPlaying === 'loading'){
            playerImage = podcastLoading;
          }else{
            onClickMethod = () => this.props.onPlayerClick();
          }
        }
      }
      return (
      <div className="container podcast-detail" >
        <div className="row">
          <div className="col col-sm-10 offset-sm-1">
            <div className="d-flex px-3 align-items-center">
              <div>
                <img
                  onClick={onClickMethod}
                  src={playerImage}
                  alt="play"
                  className="cursor mr-15" />
              </div>
              <div>
                <h1 className="font-700 mb-2">{this.props.podcast.title.rendered}</h1>
                <p className="letter-2 link-color mb-0"><img src={calendar} alt="calendar" className="mb-2 mr-2" />
                  <Moment format="MMM D, YYYY">
                    {this.props.podcast.date}
                  </Moment>
                </p>
              </div>
            </div>
            <hr className="mt-5"/>
            <div className="d-sm-flex justify-content-center align-items-center mb-3">
              <SocialNetworks podcast={this.props.podcast?this.props.podcast:null}/>
              <ul className="mt-3 mt-sm-0 option-ul mb-0 ml-sm-0 p-0 d-flex justify-content-center align-items-center">
                <li className="letter-2 text-uppercase text">
                <a href={this.props.podcast.meta.audio_file} target="_blank" rel="noopener noreferrer">
                descargar</a></li>
                <li className="letter-2 text-uppercase text border-primary transcripcion collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <ScrollIntoView selector="#accordion">
                    <button className="btn btn-link text-uppercase letter-2" onClick={this.change_status_transcription}>transcripción</button>
                  </ScrollIntoView>
                </li>
              </ul>
            </div>
            <div className="content-image">
              {
                this.props.image &&
                <img
                  src={this.props.image}
                  className="blog-image img-fluid m-auto d-block w-100"
                  alt="featured blog"
                />
              }
              {
                this.props.caption &&
                <div
                  className="caption text-center py-2 family-as"
                >
                  {renderHTML(this.props.caption)}
                </div>
              }
            </div>
            <div className="mt-5 fs-18 excerpt-podcast" id="content">
              {this.props.podcast.content.rendered !== "" ? (
                renderHTML(this.props.podcast.content.rendered)
              ):(
                <p>Este podcast no tiene descripción</p>
              )}
            </div>

            <div className="mt-5 fs-18 trascription-podcast mb-4" id="accordion">
            {this.state.show_transcription &&
                 <ScrollIntoView selector="#content">
                 <span className="go-up" onClick={this.close_transcription}>
                    <img src={arrow} alt="up arrow" className="img-fluid"/>
                  </span>
               </ScrollIntoView>}

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0 h4 d-flex justify-content-between align-items-center">
                      <span className="text-uppercase link-color font-700">Transcripción</span>
                    <button ref={this.btnTranscription} onClick={this.change_status_transcription} className={this.state.show_transcription?"btn btn-link":"btn btn-link collapsed"} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <img src={arrow} alt="up arrow" className="img-fluid"/>
                    </button>
                  </h5>
                </div>

                <div id="collapseOne" className={this.state.show_transcription?"collapse show":"collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                  <div className="card-body">
                    {this.props.podcast.extra_fields.transcripcion !== "" ? (
                      renderHTML(this.props.podcast.extra_fields.transcripcion)
                    ):(
                      <p>Este podcast no tiene transcripción</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    playingPodcast: state.podcasts.playingPodcast,
    currentlyPlaying: state.podcasts.currentlyPlaying
  };
};

PodcastDetail = connect(mapStateToProps)(PodcastDetail);

export default PodcastDetail;
