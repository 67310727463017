export const FACEBOOK = "FACEBOOK";
export const TWITTER = "TWITTER";
export const MAIL = "MAIL";
export const WHATSAPP = "WHATSAPP";

export const share = (socialMedia, slug = null, title = "") => {
  var url;
  var text;
  var twitterText;

  if (slug) {
    url = window.location.protocol + '//' + window.location.host + '/' + slug
    text = `${title} - Relatos Anfibios`;
    twitterText = `${title} - `;
    if (url !== window.location.href) {
      url = window.location.href;
      text = `Relatos Anfibios`;
      twitterText = ``;
    }
  } else {
    url = window.location.href;
    text = `Relatos Anfibios`;
    twitterText = ``;
  }

  switch (socialMedia) {
    case FACEBOOK:
      return `https://www.facebook.com/sharer/sharer.php?u=${url}`
    case TWITTER:
      text = twitterText;
      return `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=RelatosAnfibios`;
    case MAIL:
      return `mailto:?subject=${text}&body=${url}`
    case WHATSAPP:
      return `https://api.whatsapp.com/send?text=${text} ${url}`
    default:
      return;
  }
}
