import React, { Component } from 'react';
import '../App.css';
import Podcast from '../pages/Podcast'

class Detail extends Component {
  constructor(props){
    super(props);
    
    const { match: { params } } = this.props;
    this.state = {podcastSlug : params.slug};
    
  }

  componentDidUpdate(previousProps, previousState){
    var { match: { params } } = this.props;

    if (previousProps.match.params!== this.props.match.params) {

      this.setState({podcastSlug : params.slug});
    }

  }

  render() {
    return (
      <div>
        <Podcast onPlayerClick={this.props.onPlayerClick} slug={this.state.podcastSlug}/>
      </div>
    );
  }
}

export default Detail;
