import React, { Component } from 'react';
import LastPosts from '../components/posts/LastPosts';
import FeaturedPost from '../components/posts/FeaturedPost';
import { getPodcasts, updatePlayingPodcast }  from "../actions/podcasts";
import Title from '../components/globals/Title';
import { connect } from "react-redux";


class Home extends Component {

  constructor(props){
    super(props);
    this.state = {page: 1};
  }

  componentDidMount() {
    this.props.getPodcasts(this.state.page);
  }

  render() {
    return (
      <div className="App">
        <FeaturedPost
          onPlayerClick={this.props.onPlayerClick}
          onPlay={this.props.updatePlayingPodcast}
          podcast={this.props.podcasts[0]}
          home={true}
        />
        <div className="related-post-list container px-5 mt-5">
          <Title title="Últimos Episodios" subtitle="—todos los episodios—" link={true}/>
          <div className="row mb-5 pb-4">
            <LastPosts podcast={this.props.podcasts[1]}/>
            <LastPosts podcast={this.props.podcasts[2]}/>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { podcasts: state.podcasts.podcasts };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPodcasts: (page) => {dispatch(getPodcasts(page))},
    updatePlayingPodcast: (podcast) => {dispatch(updatePlayingPodcast(podcast))},
  }
}

const List = connect(mapStateToProps, mapDispatchToProps)(Home);

export default List;
