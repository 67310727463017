import React, { Component } from 'react';
import NavItems from './Navitems'
import facebook from '../../assets/images/facebook.png';
import twitter from '../../assets/images/twitter.png';
import { share, FACEBOOK, TWITTER } from '../globals/share.js';
import BrandLogo from "../globals/BrandLogo";
import { connect } from "react-redux";

class Header extends Component {
  constructor(props) {
    super(props);

    this.button = React.createRef()
    this.navbar = React.createRef()
  }

  sharePage = (socialMedia) => {
    var win;
    var slug = this.props.currentPodcast ? this.props.currentPodcast.slug : null;
    var title = this.props.currentPodcast ? this.props.currentPodcast.title.rendered : "";
    if(slug){
      win = window.open(share(socialMedia, slug, title), '_blank');
    }else{
      win = window.open(share(socialMedia, null, title), '_blank');
    }

    win.focus();
  }

  clickEvent = (event) => {
    if (!this.button.current.contains(event.target)) {
      if (this.navbar.current.className.search('show') > 0) {
        this.button.current.click()
      }
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickEvent)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickEvent)
  }

  render() {
    return (
      <div id="header" className={`${this.props.page}`}>
        <nav className="navbar navbar-expand-lg bg-transparent px-md-5 mb-md-5 pb-md-3">
          <div className="navbar-brand">
            <BrandLogo />
          </div>
          <button ref={this.button} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
          <div ref={this.navbar} className="collapse navbar-collapse" id="navbarSupportedContent">
            <NavItems class="navbar-nav mr-auto"/>
          </div>
          <div className="icons-section ml-3 d-flex">
            <div className="facebook-section px-2">
              <button className="btn btn-link"  onClick={() => {this.sharePage(FACEBOOK)} }>
                <img src={facebook} className="facebook" alt="logo" />
              </button>
            </div>
            <div className="twitter-section pl-2">
              <button className="btn btn-link" onClick={() => {this.sharePage(TWITTER)} }>
                <img src={twitter} className="twitter" alt="logo" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentPodcast: state.podcasts.currentPodcast,
  };
};

Header = connect(mapStateToProps)(Header);

export default Header;
