import React, { Component } from 'react';
import About from '../components/about/About';

class AboutUs extends Component {
  render() {
    return (
      <About/>
    );
  }
}

export default AboutUs;
