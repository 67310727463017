import React from "react";
import { Link } from 'react-router-dom';
import logo_img from '../../assets/images/relatos-anfibios.png';
import logo_img_2 from '../../assets/images/relatos-anfibios-2.png';

const BrandLogo = () => {
  return (
    <Link to="/">
      <img src={logo_img} className="img-fluid logo d-block d-sm-none d-md-none" alt="logo" />
      <img src={logo_img_2} className="img-fluid logo d-none d-sm-block d-md-block" alt="logo" />
    </Link>
  );
};

export default BrandLogo;
