import {
  UPDATE_PODCASTS,
  UPDATE_PODCAST,
  UPDATE_PODCAST_RECOMMENDED,
  CLEAR_PLAYING_PODCAST,
  UPDATE_PLAYING_PODCAST,
  UPDATE_CURRENTLY_PLAYING } from '../actions/podcasts';

const initialState = {
  podcasts: [],
  page: 1,
  currentPodcast: null,
  playingPodcast: null,
  nextPodcast: null,
  previousPodcast: null,
  totalPages:null,
  currentlyPlaying: false,
}

export const podcasts = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PODCASTS:
      return {
        currentPodcast: state.currentPodcast,
        podcasts: action.payload.podcasts,
        page: action.payload.page,
        playingPodcast: state.playingPodcast,
        nextPodcast: state.nextPodcast,
        previousPodcast: state.previousPodcast,
        totalPages: action.payload.totalPages,
        currentlyPlaying: state.currentlyPlaying
      };
    case UPDATE_PODCAST:
      return {
        currentPodcast: action.payload.currentPodcast,
        podcasts: state.podcasts,
        page: state.page,
        playingPodcast: state.playingPodcast,
        nextPodcast: state.nextPodcast,
        previousPodcast: state.previousPodcast,
        totalPages: state.totalPages,
        currentlyPlaying: state.currentlyPlaying
      };
    case UPDATE_PODCAST_RECOMMENDED:
      return {
        currentPodcast: action.payload.currentPodcast,
        podcasts: action.payload.podcasts,
        page: state.page,
        playingPodcast: state.playingPodcast,
        nextPodcast: state.nextPodcast,
        previousPodcast: state.previousPodcast,
        totalPages: state.totalPages,
        currentlyPlaying: state.currentlyPlaying
      };
    case UPDATE_PLAYING_PODCAST:
      return {
        currentPodcast: state.currentPodcast,
        podcasts: state.podcasts,
        page: state.page,
        playingPodcast: action.payload.playingPodcast,
        nextPodcast: action.payload.nextPodcast,
        previousPodcast: action.payload.prevPodcast,
        totalPages: state.totalPages,
        currentlyPlaying: 'loading'
      };
    case UPDATE_CURRENTLY_PLAYING:
      return {
        currentPodcast: state.currentPodcast,
        podcasts: state.podcasts,
        page: state.page,
        playingPodcast: state.playingPodcast,
        nextPodcast: state.nextPodcast,
        previousPodcast: state.previousPodcast,
        totalPages: state.totalPages,
        currentlyPlaying: action.payload
      };
    case CLEAR_PLAYING_PODCAST:
      return {
        currentPodcast: state.currentPodcast,
        podcasts: state.podcasts,
        page: state.page,
        totalPages: state.totalPages,
        playingPodcast: null,
        nextPodcast: null,
        previousPodcast: null,
        currentlyPlaying: false,
      };
    default:
      return state;
  }
}
