import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Default from './pages/Default'
import Detail from './pages/Detail'
import Episode from './pages/Episode'
import AboutUs from './pages/AboutUs'
import BasicSoundPlayer from './components/soundPlayer/SoundPlayer'
import { connect } from "react-redux";
import Header from './components/globals/Header'
import Footer from './components/globals/Footer'
import {
  updatePlayingPodcast,
  clearPlayingPodcast,
  updateCurrentlyPlaying
} from './actions/podcasts'
import TagManager from 'react-gtm-module'


if(process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayerName: 'Podcast',
  }
  TagManager.initialize(tagManagerArgs)
}

class App extends Component {

  constructor(props) {
    super(props)

    this.playerRef = React.createRef();
    this.state = {
      activeTitle: 'Relatos Anfibios'
    }
    this.setTitle = this.setTitle.bind(this)
    this.onPlayerClick = this.onPlayerClick.bind(this)
  }

  setTitle(title) {
    this.setState({
      activeTitle: title
    })
    document.title = title + ' | Relatos Anfibios'
  }

  onPlayerClick(){
    this.playerRef.current.soundCloudAudio.play();
  };

  render() {
    return (
      <div className='main-div'>
      {this.props.playingPodcast && <BasicSoundPlayer
        ref={this.playerRef}
        key={this.props.playingPodcast.id}
        updatePlayingPodcast={this.props.updatePlayingPodcast}
        clearPlayingPodcast={this.props.clearPlayingPodcast}
        nextPodcast={this.props.nextPodcast}
        updateCurrentlyPlaying={this.props.updateCurrentlyPlaying}
        previousPodcast={this.props.previousPodcast}
        streamUrl={this.props.playingPodcast.meta.audio_file}
        trackTitle={this.props.playingPodcast.title.rendered}
        preloadType="auto" />}
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props)=> <div><Header shareTitle="Inicio" page="home"/><Default onPlayerClick={this.onPlayerClick} {...props}/><Footer/></div>}
          />
          <Route
            path="/episodios"
            render={(props)=> <div><Header shareTitle="Episodios" page="episodes"/><Episode {...props}/><Footer/></div>}
            setTitle={this.setTitle} />
          <Route
            path="/nosotros"
            render={(props)=> <div><Header shareTitle="Acerca de Nosotros" page="about-us"/><AboutUs {...props}/><Footer/></div>}
            setTitle={this.setTitle}/>
          <Route
            path="/:slug"
            render={(props)=> <div><Header shareTitle={null} page="podcast"/><Detail onPlayerClick={this.onPlayerClick} {...props}/><Footer/></div>} />
        </Switch>
      </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    playingPodcast: state.podcasts.playingPodcast,
    nextPodcast: state.podcasts.nextPodcast,
    previousPodcast: state.podcasts.previousPodcast,
  };
};

App = connect(mapStateToProps, {updatePlayingPodcast, clearPlayingPodcast, updateCurrentlyPlaying})(App);

export default App;
