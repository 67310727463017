import React, { Component } from 'react';
import './FeaturedPost.css';
import renderHTML from 'react-render-html';
import blog from '../../assets/images/blog-featured-episode.png';
import playingGif from '../../assets/images/playing.gif';
import play from '../../assets/images/btn-play.png';
import blueArrow from '../../assets/images/blue_arrow.png';
import calendar from '../../assets/images/calendar.png';
import podcastLoading from '../../assets/images/podcast-loading.gif';
import placeholder from '../../assets/images/placeholder.gif';
import { connect } from "react-redux";

import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';


class FeaturedPost extends Component {

  render() {
    var podcast = this.props.podcast;
    if (!podcast) {
      return (
        <div className="text-center my-5"><img className="placeholder-img-top img-fluid" src={placeholder} alt="placeholder"/></div>
      )
    }

    let image = blog;
    if(podcast.thumbnails["featured-episode"]){
      image = podcast.thumbnails["featured-episode"];
    }
    var onClickMethod = () => this.props.onPlay(podcast);
    var playerImage = play;
    if (podcast && this.props.playingPodcast) {
      if (podcast.slug === this.props.playingPodcast.slug) {
        if (this.props.currentlyPlaying === 'playing') {
          playerImage = playingGif;
        }else if (this.props.currentlyPlaying === 'loading'){
          playerImage = podcastLoading;
        }else{
          onClickMethod = () => this.props.onPlayerClick();
        }
      }
    }
    return (
      <div className="featured-blog container px-5 pb-5">
        {!this.props.home &&
          <MetaTags>
            <title>{podcast.title.rendered}</title>
            <meta name="description" content={podcast.excerpt.rendered} />
            <meta property="og:title" content={podcast.title.rendered} />
            <meta property="og:image" content={image} />
            <meta name="og:description" content={podcast.excerpt.rendered} />
            <meta property="twitter:title" content={podcast.title.rendered} />
            <meta property="twitter:image" content={image} />
            <meta name="twitter:description" content={podcast.excerpt.rendered} />
          </MetaTags>}
        {this.props.home &&
          <MetaTags>
            <title>Relatos Anfibios - Podcast</title>
            <meta name="description" content="Contamos historias por la defensa de los derechos humanos en Colombia y el Sur-Global. La ONG colombiana Dejusticia y Cartagena Federal nos aventuramos a experimentar éste activismo narrativo." />
            <meta property="title" content="Relatos Anfibios - Podcast" />
          <meta property="og:image" content="https://wp.relatosanfibios.com/wp-content/uploads/2018/12/Relatos-anfibios-03-16.jpg" />
            />
          </MetaTags>}
        <div className="row mb-4">
          <div className="col-12 col-md-6 order-2 order-md-1 mt-3 mt-md-0 pr-md-5">
            <div className="post-excerpt text-left d-flex h-100 flex-column pr-md-5 justify-content-center">
              <div className="row">
                <div className="d-flex align-items-center align-items-md-start col-4 col-md-12">
                  <img
                    onClick={onClickMethod}
                    src={playerImage}
                    className="play-image img-fluid mb-md-4 cursor"
                    alt="featured blog" />
                </div>
                <div className="d-flex col flex-column-reverse flex-md-column col-md-12">
                  <p className="text-uppercase letter-2 link-color mb-1 font-700">
                    <img src={calendar} alt="calendar" className="mb-2 mr-2" />
                    <Moment format="MMM D, YYYY">
                    {podcast.date}
                    </Moment>
                  </p>
                  <Link className="post-title" to={`/${podcast.slug}`}><h1 className="post-title"> {podcast.title.rendered}</h1></Link>
                </div>
                <div className="col-12">
                  <div className="object text-md-right d-block d-sm-none">
                    <Link to={`/${podcast.slug}`}>
                      <img src={image} className="blog-image img-fluid w-100" alt="featured blog" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fs-18 text-left">
                {renderHTML(podcast.excerpt.rendered)}
              </div>
              <div className="d-flex justify-content-center justify-content-md-end">
                <Link to={`/${podcast.slug}`} className="d-flex btn btn-outline-primary text-uppercase rounded-0 p-0 align-items-center">
                  <span className="text letter-2 font-700">Explora <br className="d-sm-none d-block"/> el episodio</span> <span className="image"><img src={blueArrow} alt="right arrow"/></span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <div className="object text-md-right d-none d-sm-block">
              <Link to={`/${podcast.slug}`}>
                <img src={image} className="blog-image img-fluid w-100" alt="featured blog" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    playingPodcast: state.podcasts.playingPodcast,
    currentlyPlaying: state.podcasts.currentlyPlaying
  };
};

FeaturedPost = connect(mapStateToProps)(FeaturedPost);

export default FeaturedPost;
