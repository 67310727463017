import React, { Component } from 'react'
import SocialNetworks from '../socialNetworks/SocialNetworks'
import NavItems from './Navitems'
import spotify from '../../assets/images/spotify.png'
import google from '../../assets/images/gpodcasts.png'
import sound from '../../assets/images/sound.png'
import dejusticia_logo from '../../assets/images/dejusticia.png'
import cartagenafederal from '../../assets/images/cartagena_logo.png'
import swapps from '../../assets/images/swapps.png'
import apple_icon from '../../assets/images/apple-podcast.png'
import { connect } from "react-redux"
import BrandLogo from "../globals/BrandLogo"
import "./Footer.css"

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer p-5">
          <div className="container py-4 px-0">
            <div className="row">
              <div className="col-12 col-md-5 text-left" id="footer">
                <div className="row no-gutters">
                  <div className="brand_logo col-12 col-sm-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <a target="_blank" rel="noopener noreferrer" href={"https://www.dejusticia.org/"}>
                      <img className="img-fluid" src={dejusticia_logo} alt="Dejusticia logo" />
                    </a>
                  </div>
                  <div className="brand_logo col-12 col-sm-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <a target="_blank" rel="noopener noreferrer" href={"https://www.cartagenafederal.com/"}>
                      <img className="img-fluid" src={cartagenafederal} alt="Cartagena Federal logo" />
                    </a>
                  </div>
                </div>
                <span className="text-uppercase letter-1 link-color font-700">cómo escucharnos</span>
                <p className="text-left fs-18">Puedes escucharnos en tu aplicación de podcast favorita o aquí mismo en la página.</p>
                <ul className="row text-center m-0 p-0 mt-4 fs-18 player-options">
                  <li className="col">
                    <a target="_blank" rel="noopener noreferrer"  href="https://www.spotify.com/co/">
                      <img src={spotify} className="podcast-image" alt="spotify" />
                      <p>Spotify</p>
                    </a>
                  </li>
                  <li className="col">
                    <a target="_blank" rel="noopener noreferrer"  href="https://www.apple.com/itunes/podcasts/">
                      <img src={apple_icon} className="podcast-image" alt="podcast" />
                      <p>Apple Podcasts</p>
                    </a>
                  </li>
                  <li className="col">
                    <a target="_blank" rel="noopener noreferrer"  href="https://play.google.com/store/apps/details?id=com.google.android.apps.podcasts">
                      <img src={google} className="podcast-image" alt="podcast" />
                      <p>Google Podcasts</p>
                    </a>
                  </li>
                  <li className="col">
                    <a target="_blank" rel="noopener noreferrer" href={"https://soundcloud.com/relatos-anfibios/"}>
                      <img src={sound} className="podcast-image" alt="sound" />
                      <p >Soundcloud</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="pl-md-5 col-12 col-md-7 text-left mt-md-0 mt-5">
                <div className="footer-logo d-flex">
                  <div className="mt-4 break">
                    <BrandLogo />
                    <div className="footer-info">
                      <p className="fs-18">
                        Relatos Anfibios es un experimento de <a href="https://www.dejusticia.org" target="_blank" rel="noopener noreferrer">Dejusticia</a> y <a href="https://www.cartagenafederal.com/" target="_blank" rel="noopener noreferrer">Cartagena Federal</a> que nace del gusto por divulgar
                        historias relacionadas a la defensa de los derechos humanos en Colombia y el Sur-Global.
                      </p>
                      <div>
                        <a
                          target="_top"
                          href="mailto:relatosanfibiospodcast@dejusticia.org"
                          className="fs-18 contact-us"
                        >
                          Contáctanos en:&nbsp;<br/>
                          <span className="word-wrap">relatosanfibiospodcast@dejusticia.org</span>
                        </a>
                      </div>
                      <div className="my-4">
                        <NavItems class="footer-nav m-0 py-3 px-0" />
                      </div>
                      <b className="text-center text-md-left d-block mt-4 copy fs-18">© 2018 Dejusticia. Todos los derechos reservados.</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-11 text-center brand_powered">
                <a className="" href={"https://swapps.com/"}>Powered by <img alt="Powered by Swapps" src={swapps}></img>Swapps</a>
              </div>
            </div>
          </div>

          <div className="modal fade" id="shareModal" tabIndex="-1" role="dialog" aria-labelledby="shareModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content bg-transparent border-0">
                <div className="modal-body">
                  <p className="font-700 text-center text-white">Compartir este espisodio</p>
                  <div className="share-div bg-white p-3">
                    <SocialNetworks podcast={this.props.playingPodcast ? this.props.playingPodcast : null} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { playingPodcast: state.podcasts.playingPodcast };
};
export default connect(mapStateToProps)(Footer);;

