import React, { Component } from 'react';
import EpisodesDetail from '../components/episodes/EpisodesDetail';


class Episode extends Component {  
  render() {
    return (
      <EpisodesDetail/>
    );
  }
}

export default Episode;
