import React, { Component } from 'react';


class Carousel extends Component {
  render() {
    return (
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="false">
        <div className="carousel-inner">
          {this.props.images.map((image,j) =>
            <div className={`carousel-item ${j === 0 ? 'active' : ''}`}  key={j}>
              <img src={image.sizes['650-400']} className="img-fluid" alt="gallery" />
            </div>
          )}
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default Carousel;
