import React, { Component } from 'react';

class InternalTitle extends Component {

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 text-center">
          <h1 className="font-700">{this.props.title}</h1>
          <hr className="bg-primary border-2 mb-5 mt-3" />
        </div>
      </div>
    );
  }
}

export default InternalTitle;
