import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';

class NavItems extends Component {

  render() {
    return (
      <ul className={`${this.props.class}`}>
				<li className="nav-item">
				<ScrollIntoView selector="#header">
					<Link
					  className="nav-link text-uppercase letter-1"
					  to="/episodios"
					 >
						Episodios
					</Link>
					</ScrollIntoView>
				</li>
				<li className="nav-item">
				<ScrollIntoView selector="#footer">
					<button className="btn btn-link anchor nav-link text-uppercase letter-1 cursor" >
						Cómo escucharnos
					</button>
					</ScrollIntoView>
				</li>
				<li className="nav-item">
				<ScrollIntoView selector="#header">
					<Link className="nav-link text-uppercase letter-1" to="/nosotros">
						Nosotros
					</Link>
				</ScrollIntoView>
				</li>
			</ul>
    );
  }
}

export default NavItems;
