import React, { Component } from 'react';
import { getPodcast, updatePlayingPodcast, NO_PODCAST }  from "../actions/podcasts";
import { connect } from "react-redux";
import PodcastDetail from '../components/posts/PodcastDetail';
import RelatedPodcast from '../components/posts/RelatedPodcast';
import LastPosts from '../components/posts/LastPosts';
import Title from '../components/globals/Title';
import defaultImage from '../assets/images/blog-episode-detail.png';
import podcastPlaceholder from '../assets/images/placeholder-loading.gif';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';



class Podcast extends Component {

    componentDidMount() {
      this.props.getPodcast(this.props.slug);
      window.scrollTo(0, 0)
    }
    componentDidUpdate(previousProps, previousState){

      if (previousProps.slug!== this.props.slug) {
         this.props.getPodcast(this.props.slug);
         window.scrollTo(0, 0)
      }
    }
    render(){
      var podcast = this.props.currentPodcast;
      var loading = true;
      if (podcast) {
        loading = podcast.slug !== this.props.slug;
      }
      if( podcast === NO_PODCAST){
        return (
          <div className="container">
            <div className="row page-404">
              <div className="col-12">
                <h1 className="text-center my-5">El podcast al que intenta acceder no existe</h1>
                <p className="text-center my-5">
                  Para ver un listado de todos nuestros podcasts por favor vaya a: <br/>
                  <Link to="/episodios">Episodios</Link>
                </p>
              </div>
            </div>
          </div>
        )
      } else {
        if (podcast) {
          loading = podcast.slug !== this.props.slug;
        }
        if (!podcast || loading) {
          return (
            <div className="text-center my-5"><img className="placeholder-img-top img-fluid" src={podcastPlaceholder} alt="loading"/></div>
          )
        }
      }

      let podcasts_recommended = this.props.podcasts;
      let image = defaultImage;
      let caption = false;

      if (podcast._embedded['wp:featuredmedia'][0].code !== "rest_forbidden") {
          image = podcast._embedded['wp:featuredmedia'][0].media_details.sizes['episode-detail'].source_url;
          caption = podcast._embedded['wp:featuredmedia'][0].caption.rendered
      }

      if (podcast.thumbnails && image === defaultImage) {
        image = podcast.thumbnails['episode-detail'];
      }

      let podcast_acf = podcast.extra_fields;
      let related_content = podcast_acf?podcast_acf.contenido_relacionado:false;
      return(
        <div className="podcast-detail">
          <MetaTags>
            <meta property="og:title" content={podcast.title.rendered} />
            <meta property="og:image" content={image} />
            <meta name="og:description" content={podcast.excerpt.rendered} />
            <meta property="twitter:title" content={podcast.title.rendered} />
            <meta property="twitter:image" content={image} />
            <meta name="twitter:description" content={podcast.excerpt.rendered} />
          </MetaTags>
          <PodcastDetail
            onPlayerClick={this.props.onPlayerClick}
            onPlay={this.props.updatePlayingPodcast}
            podcast={podcast}
            image={image}
            caption={caption}
          />
          <RelatedPodcast relatedpodcast={related_content}  podcastAcf={podcast_acf}/>
          <div className="px-5 pb-4 container">
            <Title title="Recomendamos" subtitle="Si te gustó este episodio" other="también te puede interesar" link={false}/>
            {podcasts_recommended?
            (
            <div>
              <div className="row mb-5">
                <LastPosts podcast={podcasts_recommended[0]}/>
                <LastPosts podcast={podcasts_recommended[1]}/>
              </div>
            </div>
            ):(
              <h3 className="mt-3 mb-5">No hay recomendados</h3>
            )}
          </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { currentPodcast: state.podcasts.currentPodcast,
            podcasts: state.podcasts.podcasts };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPodcast: (slug) => {dispatch(getPodcast(slug))},
    updatePlayingPodcast: (podcast) => {dispatch(updatePlayingPodcast(podcast))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Podcast);

