import React, { Component } from 'react';
import InternalTitle from '../globals/InternalTitle'
import about from '../../assets/images/About-us.png';
import './About.css'


class About extends Component {
  render() {
    return (
      <div className="container about">
        <InternalTitle title="Relatos anfibios"/>
        <div className="row mb-5">
          <div className="col-12 col-sm-10 offset-sm-1">
            <div className="content-image mb-5 pt-3">
              <img src={about} className="img-fluid d-block m-auto" alt="about us"/>
              <div className="caption text-center py-2 family-as mt-3">Ilustración: Tobías Arboleda</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 text-sm-right">
                <p className="family-as left-text">
                Nuestro equipo de periodistas y de investigadores se unió con Cartagena
                Federal para crear este formato de podcast anfibio, orientado a hacer activismo narrativo.
                </p>
              </div>
              <div className="col-sm-8">
                <p>
                En Dejusticia practicamos la investigación anfibia, que busca activar
                una conversación entre el mundo introvertido de la academia y los centros
                de pensamiento con el ecosistema extrovertido de los medios de comunicación,
                el activismo y el debate público. Llevamos años experimentando cómo ese diálogo
                puede ser útil para el cambio social, y hemos sido testigos de que la
                apropiación de herramientas periodísticas permite elevar nuestras investigaciones
                 a debates de interés nacional y regional. Relatos Anfibios Podcast es otro hijo de
                 esa exploración.
                </p>
                <p>
                Nuestro equipo de periodistas y de investigadores se unió con Cartagena
                Federal para crear este formato de podcast anfibio, orientado a hacer
                activismo narrativo. Para Dejusticia, esta es una nueva forma de visibilizar
                las historias, luchas y personajes de nuestras investigaciones, que muchas
                veces se estaban quedando escondidos en textos académicos. Y para Cartagena
                Federal es una nueva apuesta por la difusión de historias en defensa de los
                derechos humanos, no solo en Colombia sino en el Sur Global.

                </p>
                <p>
                En Relatos Anfibios tomamos los micrófonos, nos fuimos a viajar, y
                ahora es el momento de narrar.  En esta primera temporada comenzamos en los
                cultivos de hoja de coca del Putumayo, luego pasamos por la región de los
                Montes de María, de allí nos dirigimos a Cúcuta, y luego cruzamos la frontera
                hasta Caracas, Venezuela. El resultado son cuatro episodios que se podrán
                escuchar entre diciembre del 2018 y febrero del 2019, y la promesa de una
                segunda temporada en la que ya estamos trabajando.
                </p>
                <p>
                Desde Dejusticia y Cartagena Federal, esto es Relatos Anfibios, quédense con nosotros.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
