import React, { Component } from 'react';
import blueArrow from '../../assets/images/blue_arrow.png';
import InternalTitle from '../globals/InternalTitle'
import EpisodesList from './EpisodesList'
import './EpisodesDetail.css';
import { getPodcasts, updatePlayingPodcast }  from "../../actions/podcasts";
import { connect } from "react-redux";
import placeholderDetail from '../../assets/images/placeholder-detail.gif';


class EpisodesDetail extends Component {
  constructor(props){
    super(props);
    this.state = {page: 1, listing: []};
    this.showLoader = false
    this.showLoadButton = true;
  }

  componentDidMount() {
    this.props.getPodcasts(this.state.page);
  }
  loadMore(){
    var page = this.state.page+1;
    if( page <= this.props.totalPages){
      this.showLoader = true;
      this.setState({page:page});
      this.props.getPodcasts(page);
      if(page === parseInt(this.props.totalPages,10)){
        this.showLoadButton = false;
      }
    }


  }
  componentDidUpdate(previousProps, previousState){

    if (previousProps.podcasts!== this.props.podcasts) {
      this.setState({listing:this.state.listing.concat(this.props.podcasts)});
      this.showLoader = false
    }
  }
  render() {
    var podcasts = this.state.listing;
    if (!podcasts || podcasts.length === 0) {
      return (
        <div>
          <div className="text-center my-5"><img className="placeholder-img-top img-fluid" src={placeholderDetail} alt="placeholder"/></div>

          <div className="text-center my-5"><img className="img-fluid" src={placeholderDetail}
          alt="placeholder"/></div>

          <div className="text-center my-5"><img className="img-fluid" src={placeholderDetail}
          alt="placeholder"/></div>
        </div>
      )
    }

    return (
      <div className="container episodes">
        <InternalTitle title="Episodios"/>
        {this.state.listing.map((podcast,i) =>
          <EpisodesList podcast={podcast} key={i}/>
        )}

        {this.showLoader?(<div className="load-more"><img className="img-fluid" src={placeholderDetail} alt="loading"/></div>):(<div></div>)}
        {this.showLoadButton?
        ( <div className="text-center mb-5">
            <button className="d-flex m-auto btn btn-outline-primary text-uppercase rounded-0 p-0 align-items-center">
              <span className="text letter-2 font-700" onClick={() => {this.loadMore()} }>cargar más episodios</span>
              <span className="image"><img src={blueArrow} alt="right arrow"/></span>
            </button>
          </div>):(<div></div>)
        }

      </div>
    );
  }
}
const mapStateToProps = state => {
  return { podcasts: state.podcasts.podcasts, totalPages: state.podcasts.totalPages };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPodcasts: (page) => {dispatch(getPodcasts(page))},
    updatePlayingPodcast: (podcast) => {dispatch(updatePlayingPodcast(podcast))},
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EpisodesDetail);;
