import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import calendar from '../../assets/images/calendar.png';
import blog from '../../assets/images/blog-episodes-list.png';

class EpisodesList extends Component {

  render() {
    let image = blog;
    if(this.props.podcast.thumbnails["episodes-list"]){
      image = this.props.podcast.thumbnails["episodes-list"];
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1">
            <div className="row mb-5">
              <div className="col-md-4">
                <Link to={`/${this.props.podcast.slug}`}>
                  <img src={image} className="blog-image img-fluid w-100" alt="episode" />
                </Link>
              </div>
              <div className="col-md-8">
                <div className="calendar text-left mb-0 mt-3 mt-md-2">
                  <div className="link-color letter-2 text-uppercase font-700">
                    <img src={calendar} alt="calendar" className="mr-2 mb-2"/>
                  <span>
                    <Moment format="MMM D, YYYY">
                    {this.props.podcast.date}
                    </Moment>
                  </span></div>
                </div>
                <h4 className="mb-2">
                  <Link to={`/${this.props.podcast.slug}`} className="color-base font-700">
                    {this.props.podcast.title.rendered}
                  </Link>
                </h4>
                {renderHTML(this.props.podcast.excerpt.rendered)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EpisodesList;
