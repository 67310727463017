import React, { Component } from 'react';
import './LastPosts.css';
import renderHTML from 'react-render-html';
import blog from '../../assets/images/blog-last-episodes.png';
import plus from '../../assets/images/plus.png';
import calendar from '../../assets/images/calendar.png';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import placeholderLast from '../../assets/images/placeholder-episodes.gif';

class LastPosts extends Component {

  render() {
    var podcast = this.props.podcast;
    if (!podcast) {
      return (
        <div className="text-center my-5"><img className="img-fluid" src={placeholderLast} alt="loading"/></div>
      )
    }
    let image = blog;
    if(podcast.thumbnails["last-episodes"]){
      image = podcast.thumbnails["last-episodes"];
    }
    let acf = podcast.extra_fields;
    return (
      <div className="col-12 col-md-6 related-post mt-5">
        <div className="post-excerpt">
          <div>
            <Link to={`/${podcast.slug}`}>
              <img src={image} className="blog-image img-fluid mb-3" alt="featured blog" />
            </Link>
            <div className="calendar text-left mb-2">
              <div className="link-color letter-2 text-uppercase font-700">
                <img src={calendar} alt="calendar" className="mr-2 mb-2"/>
              <span>
                <Moment format="MMM D, YYYY">
                {podcast.date}
                </Moment>
              </span></div>
            </div>
            <Link className="text-left" to={`/${podcast.slug}`}>
              <h4 className="text-left color-base font-700">{podcast.title.rendered}</h4>
            </Link>
          </div>

          <div className="fs-18 text-left description-text">
            {renderHTML(podcast.excerpt.rendered)}
          </div>
          <div className="plus-part text-left">
            <hr/>
            {acf && acf.contenido_relacionado?(
              <p className="fs-17">
                <img
                  src={plus}
                  className="plus-image img-fluid mr-2 mb-1"
                  alt="featured blog" />
                Galería: {acf.contenido_relacionado[0].titulo} y <Link to={`/${podcast.slug}`}>otros contenidos relacionados</Link></p>
            ):(
              <p className="fs-17"><img src={plus} className="plus-image img-fluid mr-2 mb-1" alt="featured blog" />
              <Link to={`/${podcast.slug}`}>otros contenidos relacionados</Link></p>
            )}

          </div>
        </div>
      </div>
    );
  }
}

export default LastPosts;
