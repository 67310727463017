// https://github.com/kosmetism/react-soundplayer/blob/master/examples/players/AWSSoundPlayer.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withCustomAudio } from 'react-soundplayer/addons';
import {
  PlayButton,
  PrevButton,
  Progress,
  Timer } from 'react-soundplayer/components';
import { Icons } from 'react-soundplayer/components';
import "./SoundPlayer.css";
import download from "../../assets/images/download-btn.png";
import share from "../../assets/images/share-btn.png";
import refreshArrow from "../../assets/images/refresh-arrow.svg";
import undoArrow from "../../assets/images/undo-arrow.svg";

class BasicSoundPlayer extends Component {

  async componentDidMount() {

    // Watching if there are props
    await this.props

    // Watching if there is a stream url
    if (this.props.streamUrl !== "") {
      // Playing the audio
      this.props.soundCloudAudio.play()
    }
    this.updatePlayingTime = this.updatePlayingTime.bind(this);
  }

  componentDidUpdate(previousProps, previousState){
    if (this.props.seeking) {
      this.props.updateCurrentlyPlaying('loading');
    }else if(this.props.playing){
      this.props.updateCurrentlyPlaying('playing');
    }else{
      this.props.updateCurrentlyPlaying(false);
    }
  }

  updatePlayingTime(seconds){
    this.props.soundCloudAudio.audio.currentTime += seconds;
    if (!this.props.playing) {
      this.setState({pausedTime: this.props.soundCloudAudio.audio.currentTime});
    }
  }

  render() {
    const { trackTitle } = this.props;
    return (
      <div className="player-content">
        <div className="main-content d-md-flex align-items-center px-4 text-md-left text-center">
          <div className="controls">
            <PrevButton
              className="responsive-hidden pl-0 h4 button button-transparent button-grow rounded mr2"
              onPrevClick={() => {this.props.updatePlayingPodcast(this.props.previousPodcast)}}
              {...this.props} />
            <button
            type="button"
            className="responsive-hidden flex-none h4 button button-transparent button-grow rounded mr2 sb-soundplayer-btn sb-soundplayer-next-btn"
            onClick={() => {this.updatePlayingTime(-15)}}>
              <img src={undoArrow} alt="undo" className="sb-soundplayer-icon"/>
            </button>
            <PlayButton
              className="h4 button button-transparent button-grow rounded mr2"
              playing={true}
              onTogglePlay={() =>{
                if (this.props.playing) {
                  this.setState({pausedTime: this.props.soundCloudAudio.audio.currentTime});
                }else{
                  this.props.soundCloudAudio.audio.currentTime = this.state ? this.state.pausedTime : 0;
                }
              }}
              {...this.props} />
            <button
            type="button"
            className="responsive-hidden flex-none h4 button button-transparent button-grow rounded mr2 sb-soundplayer-btn sb-soundplayer-next-btn"
            onClick={() => {this.updatePlayingTime(15)}}>
              <img src={refreshArrow} alt="undo" className="sb-soundplayer-icon"/>
            </button>
            <button
            type="button"
            className="responsive-hidden flex-none h4 button button-transparent button-grow rounded mr2 sb-soundplayer-btn sb-soundplayer-next-btn"
            onClick={() => {this.props.updatePlayingPodcast(this.props.nextPodcast)}}>
              <Icons.NextIconSVG />
            </button>
          </div>
          <div className='info-section'>
            <h2 className="flex-grow-1 mb-0 fs-18">{trackTitle}</h2>
            <Timer className="mb-0 fs-18" {...this.props} />
          </div>
          <h2 className="responsive-hidden flex-grow-1 mb-0 fs-18">{trackTitle}</h2>
          <div className="responsive-hidden actions mr1 d-flex justify-content-center my-3 my-md-0">
            <Timer className="responsive-hidden mb-0 fs-18" {...this.props} />
            <button className="responsive-hidden cursor modal-button" data-toggle="modal" data-target="#shareModal">
              <img src={share} alt="share" className="ml-3 action-img"/>
            </button>
            <a className='responsive-hidden' href={this.props.streamUrl} target="_blank" rel="noopener noreferrer">
              <img src={download} alt="download" className="ml-3 action-img"/>
            </a>
          </div>
          <div className="share-modal-section">
            <button className="cursor modal-button" data-toggle="modal" data-target="#shareModal">
              <img src={share} alt="share" className="sb-soundplayer-iconaction-img"/>
            </button>
          </div>
        </div>
        <Progress
          onSeekTrack={() =>{
            if (!this.props.playing) {
              this.setState({pausedTime: this.props.soundCloudAudio.audio.currentTime});
            }
          }}
          {...this.props} />
      </div>
    );
  }
}

BasicSoundPlayer.propTypes = {
  preloadType: PropTypes.string,
  streamUrl: PropTypes.string.isRequired,
  trackTitle: PropTypes.string.isRequired
};

BasicSoundPlayer.defaultProps = {
  preloadType: 'auto'
};

export default withCustomAudio(BasicSoundPlayer);
