import React, { Component } from 'react';
import soundLeft from '../../assets/images/sound-left.svg';
import soundRight from '../../assets/images/sound-right.svg';
import { Link } from 'react-router-dom';

class Title extends Component {

  render() {
    return (
      <div className="d-flex sound-row">
        <div className="flex-grow-0 flex-md-grow-1 d-flex justify-content-center">
          <img src={soundLeft} className="sound img-fluid" alt="sound left" />
        </div>
        <div className="flex-grow-1 flex-md-grow-0 mt-4 mx-4 text-center content-title">
          <div>
            <h2 className="font-700">{this.props.title}</h2>
            <p className="font-700 link-color text-uppercase letter-2 mb-0">
              {
                this.props.link ?
                  (
                    <Link to="/episodios">{this.props.subtitle}</Link>
                  ) : (
                    this.props.subtitle
                  )
              }
            </p>
            <p className="font-700 link-color text-uppercase letter-2 last-subtitle">{this.props.other}</p>
          </div>
        </div>
        <div className="flex-grow-0 flex-md-grow-1 d-flex justify-content-center">
          <img src={soundRight} className="sound img-fluid" alt="sound right" />
        </div>
      </div>
    );
  }
}

export default Title;
